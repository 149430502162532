/* Scrollbar.css */

/* Track */
::-webkit-scrollbar {
  width: 7.6px; /* Set the width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar handle on hover */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #ddd; /* Color of the scrollbar track on hover */
}
